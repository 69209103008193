export default {
  PageValues: {
    sliderPageDesign: {
      pageStyles: {
        activeBlockTitle: "Styles",
        activeSubBlock: false,
        paddingTop: 10,
        paddingRight: 0,
        paddingBottom: 10,
        paddingLeft: 10,
        marginLeft: 10,
        marginRight: 10,
        alignItems: "center",
        textAlign: "right",
        backgroundColor: "#ffffff",
        color: "black",
        imageAlign: "left",
        imageFor: "both",
        bgImageStatus: false,
        bgImageSrc: false,
        fontFamily: "",
        fontSize: "",
        height: 540,
        width: 850,
      },

      blocksArray: [
        {
          type: "text",
          content: "Choose Your range with Precision",
          style: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 10,
            paddingBottom: 10,
            marginTop: 10,
            marginBottom: 10,
            marginLeft: 0,
            marginRight: 0,
            width: 800,
            height: 100,
            textOpacity: 100,
            color: "#000000",
            rotate: 0,
            lineHeight: 1,
            textDecoration: "unset",
            fontWeight: "bold",
            fontStyle: "normal",
            textAlign: "center",
            fontSize: 32,
            fontFamily: "Poppins",
          },
          customizeStatus: false,
          hoverStatus: false,
        },
        {
          type: "text",
          content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          style: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 10,
            paddingBottom: 10,
            marginTop: 10,
            marginBottom: 10,
            marginLeft: 0,
            marginRight: 0,
            fontSize: 14,
            width: 880,
            height: 100,
            textOpacity: 100,
            color: "#000",
            rotate: 0,
            lineHeight: 1,
            textDecoration: "unset",
            fontWeight: "normal",
            fontStyle: "normal",
            textAlign: "center",
            fontFamily: "Poppins",
          },
          customizeStatus: false,
          hoverStatus: false,
        },
        {
          type: "sliderRangeInput",
          selectedExactValue: 0,
          selectedRangeValueA: 0,
          selectedRangeValueB: 0,
          exactStarterValue:0,
          rangeAStarterValue:0,
          rangeBStarterValue:0,
          currInputMode: "exact",
          inputModes: [
            {
              id: 1,
              value: "exact",
              title: "Exact",
            },
            {
              id: 2,
              value: "range",
              title: "range",
            },
          ],
          purposeArr: [
            {
              id: 1,
              type: "price",
              title: "Price",
            },
            {
              id: 2,
              type: "age",
              title: "Age",
            },
            {
              id: 3,
              type: "other",
              title: "Other",
            },
          ],
          hideMinMax: false,
          selectedPurpose:null,
          otherPurposeKey: "",
          otherPurposePlaceholder: "Please mention your 'other' case ",
          minValue: 0,
          maxValue: 100,
          style: {
            marginTop: 5,
            marginBottom: 5,
            marginLeft: 10,
            marginRight: 10,
            mobileMarginTop: 5,
            mobileMarginBottom: 5,
            mobileMarginLeft: 10,
            mobileMarginRight: 10,
            color: "#000000",
            textDecoration: "unset",
            fontWeight: "bold",
            fontStyle: "normal",
            textAlign: "left",
            fontSize: 14,
            // height:80,
            width: 800,
            fontFamily: "Poppins",
            SliderSize: 3,
            ItemSize: 50,
            ItemAlign: "left",
            thumbSize: 22,
            valueActiveColor: "#fff",
            thumbCenterColor: "#fff",
            valueBgColor: "#000",
            thumbColor: "#fff",
            progressBg: "#ddd",
            progressFill: "#4d1b7e",
            trackHeight: 6,
            progressRadius: 8,
            minMaxFontSize:14
          },
          customizeStatus: false,
          hoverStatus: false,
        },
        {
          type: "button",
          staticButton: true,
          content: {
            backButton: {
              text: "← Back",
              hideBackButton: false,
              style: {
                backgroundColor: "#ffffff",
                color: "#000000",
                buttonWidth: 100,
                buttonHeight: "60",
                textOpacity: 100,
                backgroundOpacity: 100,
                textDecoration: "unset",
                fontFamily: "Poppins",
                fontWeight: "normal",
                fontStyle: "normal",
                fontSize: "18",
                borderRadius: "0",
                borderSize: "0",
                borderColor: "",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 15,
                paddingBottom: 15,
                textAlign: "center",
              },
            },
            nextButton: {
              text: "Next →",
              hideNextButton: false,
              style: {
                backgroundColor: "#ffffff",
                color: "#ffa920",
                buttonWidth: 100,
                buttonHeight: "60",
                textOpacity: 100,
                backgroundOpacity: 100,
                textDecoration: "unset",
                fontFamily: "Poppins",
                fontWeight: "normal",
                fontStyle: "normal",
                fontSize: "18",
                borderRadius: "0",
                borderSize: 0,
                borderColor: "",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 15,
                paddingBottom: 15,
                textAlign: "center",
              },
            },
          },
          style: {
            position: "left",
            marginTop: 0,
            marginBottom: "0",
            marginLeft: 0,
            marginRight: 0,
            lineHeight: 1,
            buttonPosition: "center",
          },
          customizeStatus: false,
          hoverStatus: false,
        },
      ],
      imageBlock: {
        src: "",
        customizeStatus: false,
        hoverStatus: false,
        style: {
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 10,
          paddingBottom: 10,
          textAlign: "center",
          backgroundColor: "none",
          width: 300,
          height: 250,

          rotate: 0,
          imageOpacity: 100,
          displayMobile: false,
          displayDesktop: false,
        },
      },
    },
    stripePageDesign: {
      name: "Payment",
      img: "/images/bar.svg",
      icon: "/images/stripe.svg",
      value: "stripePage",
      isEdit: false,
      pageDesign: {
        pageStyles: {
          activeBlockTitle: "Styles",
          activeSubBlock: false,
          paddingTop: 10,
          paddingRight: 0,
          paddingBottom: 10,
          paddingLeft: 10,
          marginLeft: 10,
          marginRight: 10,
          alignItems: "center",
          textAlign: "right",
          backgroundColor: "#ffffff",
          color: "black",
          imageAlign: "left",
          imageFor: "both",
          bgImageStatus: false,
          bgImageSrc: false,
          fontFamily: "",
          fontSize: "",
          height: 540,
          width: 850,
        },

        blocksArray: [
          {
            type: "text",
            content: "Best Payment Processing Platform?",
            style: {
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 10,
              paddingBottom: 10,
              marginTop: 10,
              marginBottom: 10,
              marginLeft: 0,
              marginRight: 0,
              width: 800,
              height: 100,
              textOpacity: 100,
              color: "#000000",
              rotate: 0,
              lineHeight: 1,
              textDecoration: "unset",
              fontWeight: "bold",
              fontStyle: "normal",
              textAlign: "center",
              fontSize: 32,
              fontFamily: "Poppins",
            },
            customizeStatus: false,
            hoverStatus: false,
          },
          {
            type: "text",
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            style: {
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 10,
              paddingBottom: 10,
              marginTop: 10,
              marginBottom: 10,
              marginLeft: 0,
              marginRight: 0,
              fontSize: 14,
              width: 880,
              height: 100,
              textOpacity: 100,
              color: "#000",
              rotate: 0,
              lineHeight: 1,
              textDecoration: "unset",
              fontWeight: "normal",
              fontStyle: "normal",
              textAlign: "center",
              fontFamily: "Poppins",
            },
            customizeStatus: false,
            hoverStatus: false,
          },
          {
            type: "price",
            text: "Get Started",
            selectedPaymentMethod: "Stripe",
            paypalPaymentDetail: {},
            stripePaymentLink: "",
            paymentTitle: "",
            paypalClientID: "",
            amountRequired: true,
            amountValue: 0,
            isRecuringPayment: false,
            RecurringPayment: [
              { id: 1, value: "month", Text: "Monthly" },
              { id: 2, value: "year", Text: "Yearly" },
              { id: 3, value: "day", Text: "Daily" },
            ],
            selectedRecurringPayment: {
              id: 1,
              value: "month",
              Text: "Monthly",
            },
            Currencies: [
              { id: 1, currencyCode: "USD", currencySymbol: "$" },
              { id: 2, currencyCode: "GBP", currencySymbol: "£" },
              { id: 3, currencyCode: "EUR", currencySymbol: "€" },
            ],
            selectedCurrency: {
              id: 1,
              currencyCode: "USD",
              currencySymbol: "$",
            },
            style: {
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 10,
              paddingBottom: 10,
              marginTop: 10,
              marginBottom: 10,
              marginLeft: 0,
              marginRight: 0,
              fontSize: 40,
              width: 800,
              height: 100,
              textOpacity: 100,
              color: "#000",
              rotate: 0,
              lineHeight: 1,
              textDecoration: "unset",
              fontWeight: "bold",
              fontStyle: "normal",
              textAlign: "center",
              fontFamily: "Poppins",
            },
            customizeStatus: false,
            hoverStatus: false,
          },
          {
            type: "paymentButton",
            content: "this is button",
            text: "Pay now",
            paypalButtonData: {
              shape: "rect",
              color: "gold",
              layout: "vertical",
              label: "paypal",
            },
            style: {
              paddingLeft: 15,
              paddingRight: 15,
              paddingTop: 15,
              paddingBottom: 15,
              backgroundColor: "#000000",
              color: "#ffffff",
              textAlign: "center",
              fontWeight: "bold",
              fontStyle: "normal",
              buttonWidth: 150,
              buttonHeight: "42",
              widthIsAuto: true,
              borderRadius: "7",
              borderSize: "0",
              borderColor: "",
              position: "center",
              textOpacity: 100,
              backgroundOpacity: 100,
              fontSize: "16",
              marginTop: 10,
              marginBottom: 10,
              marginLeft: 10,
              marginRight: 10,
              lineHeight: 1,
              textDecoration: "unset",
              fontFamily: "Poppins",
            },
            customizeStatus: false,
            hoverStatus: false,
          },
          {
            type: "button",
            content: {
              backButton: {
                text: "Back",
                hideBackButton: false,
                style: {
                  backgroundColor: "#B2AFB6",
                  color: "#FFFFFF",
                  buttonWidth: 100,
                  buttonHeight: "35",
                  textOpacity: 100,
                  backgroundOpacity: 100,
                  textDecoration: "unset",
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontStyle: "normal",
                  fontSize: 14,
                  borderRadius: 8,
                  borderSize: 0,
                  borderColor: "",
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: 15,
                  paddingBottom: 15,
                  textAlign: "center",
                },
              },
            },
            style: {
              position: "left",
              marginTop: 10,
              marginBottom: 10,
              marginLeft: 10,
              marginRight: 10,
              lineHeight: 1,
            },
            customizeStatus: false,
            hoverStatus: false,
            staticButton: true,
          },
        ],
        imageBlock: {
          src: "",
          customizeStatus: false,
          hoverStatus: false,
          style: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 10,
            paddingBottom: 10,
            textAlign: "center",
            backgroundColor: "none",
            width: 300,
            height: 250,

            rotate: 0,
            imageOpacity: 100,
            displayMobile: false,
            displayDesktop: false,
          },
        },
      },
    },

    multipleAnswerDesign: {
      name: "Multiple Answer Question",
      img: "/images/bar.svg",
      icon: "/images/multipleAnswer.svg",
      value: "multipleAnswer",
      isEdit: false,
      isMasterQuestion: false,
      pageDesign: {
        pageStyles: {
          activeSubBlock: true,
          paddingTop: 10,
          paddingRight: 10,
          paddingBottom: 10,
          paddingLeft: 10,
          marginLeft: 10,
          marginRight: 10,
          alignItems: "center",
          textAlign: "right",
          backgroundColor: "#ffffff",
          color: "black",
          imageAlign: "left",
          imageFor: "both",
          imageSrc: "",
          bgImageStatus: false,
          bgImageSrc: false,
          fontFamily: "",
          fontSize: "",
          height: 540,
          width: 850,
        },
        blocksArray: [
          {
            type: "text",
            content: "Create Multiple Answer?",
            questionTitle: true,
            style: {
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 10,
              paddingBottom: 10,
              marginTop: 10,
              marginBottom: 10,
              marginLeft: 10,
              marginRight: 10,
              width: "800",
              height: 100,
              textOpacity: 100,
              color: "#000000",
              rotate: 0,
              lineHeight: 1,
              textDecoration: "unset",
              fontWeight: "bold",
              fontStyle: "normal",
              textAlign: "initial",
              fontSize: 32,
              fontFamily: "Poppins",
            },
            customizeStatus: false,
            hoverStatus: false,
          },
          {
            type: "option",
            textOptionAlignment: "vertical",
            OptionsPerRow: 3,
            OptionsPerMobile: 1,
            selectedOption: [],
            options: [
              {
                id: 44645,
                value: "Option 1",
                image: null,
                detailValue: "",
              },
              { id: 44645453, value: "Option 2", image: null, detailValue: "" },
            ],
            optionSetting: {
              optionType: "text",
              selectedOptionStyles: {
                bgColor: "#48cae4",
                color: "#000000",
                borderStyle: "dotted",
                borderColor: "#f279f2",
                textDecoration: "unset",
                fontWeight: "bold",
                fontStyle: "normal",
                textAlign: "left",
                fontSize: 14,
                fontFamily: "Poppins",
              },
              unSelectedOptionStyles: {
                bgColor: "#48cae4",
                color: "#33d391",
                borderStyle: "dotted",
                borderColor: "#f279f2",
                textDecoration: "unset",
                fontWeight: "bold",
                fontStyle: "normal",
                textAlign: "left",
                fontSize: 14,
                fontFamily: "Poppins",
              },
            },
            style: {
              defaultTab: {
                backgroundColor: "#FFFFFF",
                bgOpacity: 100,
                borderRadius: 8,
              },
              selectedTab: {
                backgroundColor: "#FFA201",
                bgOpacity: 100,
                borderRadius: 8,
              },
              marginTop: 5,
              marginBottom: 5,
              marginLeft: 5,
              marginRight: 5,
              imageWidth: 90,
              imageHeight: 80,
              borderRadius: 3,
              textOpacity: 100,
              color: "#000000",
              textDecoration: "unset",
              fontWeight: "bold",
              fontStyle: "normal",
              textAlign: "left",
              fontSize: 14,
              fontFamily: "Poppins",
            },
            customizeStatus: false,
            hoverStatus: false,
          },
          {
            type: "button",
            content: {
              backButton: {
                text: "Back",
                hideBackButton: false,
                style: {
                  backgroundColor: "#B2AFB6",
                  color: "#FFFFFF",
                  buttonWidth: 100,
                  buttonHeight: 56,
                  textOpacity: 100,
                  backgroundOpacity: 100,
                  textDecoration: "unset",
                  fontFamily: "Poppins",
                  fontWeight: "normal",
                  fontStyle: "normal",
                  fontSize: 14,
                  borderRadius: 8,
                  borderSize: 0,
                  borderColor: "",
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: 15,
                  paddingBottom: 15,
                  textAlign: "initial",
                },
              },
              nextButton: {
                text: "Next",
                hideNextButton: true,
                style: {
                  backgroundColor: "#0397CC",
                  color: "#FFFFFF",
                  buttonWidth: 100,
                  buttonHeight: 56,
                  textOpacity: 100,
                  backgroundOpacity: 100,
                  textDecoration: "unset",
                  fontFamily: "Poppins",
                  fontWeight: "normal",
                  fontStyle: "normal",
                  fontSize: 14,
                  borderRadius: 8,
                  borderSize: 0,
                  borderColor: "",
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: 15,
                  paddingBottom: 15,
                  textAlign: "initial",
                },
              },
            },
            style: {
              position: "left",
              marginTop: 10,
              marginBottom: 10,
              marginLeft: 10,
              marginRight: 10,
              lineHeight: 1,
            },
            customizeStatus: false,
            hoverStatus: false,
          },
        ],
        imageBlock: {
          customizeStatus: false,
          hoverStatus: false,
          src: "",
          style: {
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 10,
            paddingBottom: 10,
            textAlign: "center",
            backgroundColor: "none",
            width: 300,
            height: 250,
            rotate: 0,
            imageOpacity: 100,
            displayMobile: false,
            displayDesktop: false,
          },
        },
      },
    },
  },

  twoFactAuthInput: {
    type: "Auth",
    InputType: "text",
    isEmailOTP: true,
    isPhoneOTP: false,
    ValidInputType: "number",
    OTPLength: 5,
    isOTPEnabled: true,
    isRequired: false,
    LeadTextValue: "",
    labelText: "Label",

    showLabel: true,
    submitEmailText: "Send OTP",
    verifyAuthText: "Verify OTP",
    InfoTypePlaceholder: "Please enter your email",
    VerifyOTPPlaceholder: "Please enter OTP",
    VerifyOTPText: "Confirm Your email",
    goBackBtnText: "Go Back",
    resendOtpText: "Resend Code",
    style: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 5,
      marginRight: 5,
      textOpacity: 100,
      color: "#000000",
      textDecoration: "unset",
      fontWeight: "bold",
      fontStyle: "normal",
      textAlign: "center",
      fontSize: 28,
      fontFamily: "Poppins",
      strokeOpacity: 100,
      strokeColor: "#CCCACE",
      strokeRotation: 0,
      borderSize: 1,
      borderRadius: 4,
      sendBtnBorderRadius: 20,
      borderType: "border",
      sendBtnFontWeight: "normal",
      sendBtnFontFamily: "Poppins",
      sendBtnFontSize: 14,
      sendBtnFontStyle: "normal",
      sendBtnTextDecoration: "unset",
      sendBtnBGColor: "#000000",
      sendBtnColor: "#ffffff",
      sendBtnWidth: 150,
      sendBtnHeight: 45,
      sendBtnPosition: "center",
      authInputPosition: "center",
      authInputHeight: 40,
      authInputWidth: 40,
    },
    customizeStatus: false,
    hoverStatus: false,
  },
  socialMediaDesign: {
    type: "socialLinks",
    content: "social Media",
    text: "Social Media",
    isInstagramVisible: true,
    isFacebookVisible: false,
    isLinkedInVisible: false,
    isTwitterVisible: false,
    isYoutubeVisible: false,
    isTikTokVisible: false,
    isPinterestVisible: false,
    instagramValue: "",
    facebookValue: "",
    linkedInValue: "",
    twitterValue: "",
    youtubeValue: "",
    tikTokValue: "",
    pinterestValue: "",
    instagramPlaceholder: "https://www.instagram.com/profile",
    facebookPlaceholder: "https://www.facebook.com/profile",
    linkedInPlaceholder: "https://www.linkedin.com/profile",
    twitterPlaceholder: "https://www.twitter.com/profile",
    youtubePlaceholder: "https://www.youtube.com/profile",
    tikTokPlaceholder: "https://www.tiktok.com/profile",
    pinterestPlaceholder: "https://www.pinterest.com/profile",
    style: {
      IconColor: "#18191C",
      fontWeight: "bold",
      fontStyle: "normal",
      IconSize: 3,
      IconMobileSize: 3,
      borderSize: 0,
      borderColor: "",
      position: "center",
      textOpacity: 100,
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 10,
      marginRight: 10,
      mobileMarginTop: 10,
      mobileMarginBottom: 10,
      mobileMarginLeft: 10,
      mobileMarginRight: 10,
      lineHeight: 1,
      textDecoration: "unset",
      fontFamily: "Open Sans",
    },
    customizeStatus: false,
    hoverStatus: false,
  },
  shareResultLinkDesign: {
    type: "shareLink",
    content: "share Link",
    text: "Share your quiz results!",
    isWhatsappVisible: true,
    isTelegramVisible: true,
    isCopyLinkVisible: true,
    titleValue: "",
    titlePlaceholder: "Check out your quiz results !",
    style: {
      IconColor: "#18191C",
      fontWeight: "bold",
      IconSize: 3,
      IconMobileSize: 3,
      borderSize: 0,
      borderColor: "",
      position: "center",
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 10,
      marginRight: 10,
      mobileMarginTop: 10,
      mobileMarginBottom: 10,
      mobileMarginLeft: 10,
      mobileMarginRight: 10,
      textDecoration: "unset",
      fontSize: 14,
      textOpacity: 100,
      color: "#000",
      rotate: 0,
      lineHeight: 1,
      fontStyle: "normal",
      textAlign: "initial",
      fontFamily: "Poppins",
    },
    customizeStatus: false,
    hoverStatus: false,
  },
  ImageCompDesign: {
    type: "imageComp",
    imageSrc: "https://images.quizell.com/website/default_start_page.png",
    mobileSrc: "https://images.quizell.com/website/default_start_page.png",
    useMobileImage:false,
    style: {
      backgroundColor: "#ffffff",
      backgroundSize:'contain',
      borderColor:'#000000',
      borderRadius:0,
      borderSize: 0,
      position: "start",
      desktopWidth:200,
      desktopHeight:200,
      mobileWidth:200,
      mobileHeight:200,
      fitImageWidthToContainer:false,
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 10,
      marginRight: 10,
      mobileMarginTop: 10,
      mobileMarginBottom: 10,
      mobileMarginLeft: 10,
      mobileMarginRight: 10,
    },
    customizeStatus: false,
    hoverStatus: false,
  },
  fileUploadDesign: {
    type: "fileUpload",
    content: "File Upload",
    text: "File Upload",
    isRequired: false,
    maxFileSize: 2,
    totalFiles: 1,
    uploadedFiles: [],
    fileTextLine1: "Drag your file(s) here",
    fileTextLine2: "or click to browse",

    style: {
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 10,
      marginRight: 10,
      mobileMarginTop: 10,
      mobileMarginBottom: 10,
      mobileMarginLeft: 10,
      mobileMarginRight: 10,
      lineHeight: 1,
      textOpacity: 100,
      color: "#000000",
      textDecoration: "unset",
      fontWeight: "normal",
      fontStyle: "normal",
      textAlign: "center",
      fontSize: 14,
      fontFamily: "Poppins",
      strokeOpacity: 100,
      strokeColor: "#CCCACE",
      InputHeight: 150,
    },
    customizeStatus: false,
    hoverStatus: false,
  },
  toolTipDesign: {
    type: "tooltip",
    useIcon: true,
    Icons: [
      "info",
      "info-circle",
      "info-circle-fill",
      "info-square",
      "info-square-fill",
    ],
    selectedIcon: "info",
    isTooltipVisible: false,
    content: "this is button",
    text: "Hover me",
    isBtnRounded: true,
    toolTipContent: `I'm Tooltip`,
    style: {
      // "paddingLeft": 10,
      // "paddingRight": 10,
      // "paddingTop": 10,
      // "paddingBottom": 10,
      backgroundColor: "#000000",
      color: "#ffffff",
      textAlign: "center",
      fontWeight: "bold",
      fontStyle: "normal",
      buttonWidth: 55,
      buttonHeight: 55,
      widthIsAuto: true,
      borderRadius: "5",
      borderSize: 0,
      borderColor: "",
      position: "center",
      textOpacity: 100,
      backgroundOpacity: 100,
      fontSize: "35",
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 0,
      marginRight: 0,
      lineHeight: 1,
      textDecoration: "unset",
      fontFamily: "Open Sans",
      tooltipPosition: "top",
      tooltipWidth: 350,
      tooltipHeight: 50,
      tooltipBg: "#000000",
      tooltipTextColor: "#fff",
      tooltipTextFontSize: 18,
      tooltipTextAlign: "left",
      tooltipPaddingTop: 10,
      tooltipPaddingBottom: 10,
      tooltipPaddingLeft: 10,
      tooltipPaddingRight: 10,
      tooltipMobilePaddingTop: 10,
      tooltipMobilePaddingBottom: 10,
      tooltipMobilePaddingLeft: 10,
      tooltipMobilePaddingRight: 10,
      tooltipFontFamily: "Open Sans",
      tooltipFontWeight: "bold",
      tooltipFontStyle: "normal",
      tooltipTextDecoration: "unset",
      tooltipTextBorderRadius: "5",
      tooltipTextBorderSize: 0,
      tooltipTextBorderColor: "",
      tooltipTextOpacity: 100,
      tooltipBackgroundOpacity: 100,
    },
    customizeStatus: false,
    hoverStatus: false,
  },
  htmlElementDesign: {
    type: "html",
    content: "Your HTML is here",
    showMobile: true,
    showDesktop: true,
    style: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 10,
      paddingBottom: 10,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      mobileMarginTop: 0,
      mobileMarginBottom: 0,
      mobileMarginLeft: 0,
      mobileMarginRight: 0,
      height: 40,
      width: 700,
      fontSize: 14,
      textOpacity: 100,
      color: "#000",
      rotate: 0,
      lineHeight: 1,
      textDecoration: "unset",
      fontWeight: "normal",
      fontStyle: "normal",
      textAlign: "initial",
      fontFamily: "Poppins",
    },
    customizeStatus: false,
    hoverStatus: false,
  },
  timerElementDesign: {
    type: "timer",
    timerValue: null,
    counterSetting: {
      initial: {
        visible: "true",
        text: "Reminder: ",
      },
      days: {
        visible: "true",
        text: "Days: ",
        textPosition: "first",
        value: 0,
        order: 1,
      },
      hours: {
        visible: "true",
        text: "Hours: ",
        textPosition: "first",
        value: 0,
        order: 2,
      },
      minutes: {
        visible: "true",
        text: "Minutes: ",
        textPosition: "first",
        value: 0,
        order: 3,
      },
      seconds: {
        visible: "true",
        text: "Seconds: ",
        textPosition: "first",
        value: 0,
        order: 4,
      },
    },
    timerAction: "none",
    timerActionLink: "https://example.com",
    label: {
      text: "",
      status: true,
    },
    actionText: {
      text: "Conditional Text",
      preview: true,
    },
    text: "",
    style: {
      label: {
        backgroundColor: "#ffff",
        backgroundOpacity: 100,
        color: "#00000",
        textOpacity: 100,
        fontFamily: "Poppins",
        fontWeight: "normal",
        fontStyle: "normal",
        fontSize: 14,
        textAlign: "initial",
      },

      backgroundColor: "#0397CC",
      color: "#ffffff",
      buttonWidth: 30000,
      buttonHeight: 56,
      textOpacity: 100,
      backgroundOpacity: 100,
      textDecoration: "unset",
      fontFamily: "Poppins",
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: 14,
      borderRadius: 8,
      borderSize: 0,
      borderColor: "",
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 15,
      paddingBottom: 15,
      textAlign: "initial",
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      mobileMarginTop: 0,
      mobileMarginBottom: 0,
      mobileMarginLeft: 0,
      mobileMarginRight: 0,
    },
    customizeStatus: false,
    hoverStatus: false,
  },

  skipBtnDesign: {
    type: "skipButton",
    content: "this is button",
    text: "Skip",
    style: {
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 15,
      paddingBottom: 15,
      backgroundColor: "#000000",
      color: "#ffffff",
      textAlign: "center",
      fontWeight: "bold",
      fontStyle: "normal",
      buttonWidth: 150,
      buttonHeight: "42",
      widthIsAuto: true,
      borderRadius: "7",
      borderSize: "0",
      borderColor: "",
      position: "center",
      textOpacity: 100,
      backgroundOpacity: 100,
      fontSize: "16",
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 10,
      marginRight: 10,
      mobileMarginTop: 0,
      mobileMarginBottom: 0,
      mobileMarginLeft: 0,
      mobileMarginRight: 0,
      lineHeight: 1,
      textDecoration: "unset",
      fontFamily: "Poppins",
    },
    customizeStatus: false,
    hoverStatus: false,
  },
  couponElementDesign: {
    type: "coupon",
    couponText:
      "Choose the best oportunity and get 15% off your order. Limited time offer, so act fast",
    couponBtnText: "Apply now",
    couponAppliedWhen: [],
    couponLink: "",
    style: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 10,
      paddingBottom: 10,
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 5,
      marginRight: 5,
      mobileMarginTop: 10,
      mobileMarginBottom: 10,
      mobileMarginLeft: 5,
      mobileMarginRight: 5,
      height: 40,
      width: 700,
      fontSize: 16,
      textOpacity: 100,
      color: "#fff",
      couponBg: "#9C53E3",
      rotate: 0,
      lineHeight: "1.5",
      textDecoration: "unset",
      fontWeight: "bold",
      fontStyle: "normal",
      textAlign: "left",
      fontFamily: "Poppins",
      btnfontSize: 14,

      btnColor: "#000",
      btnBgColor: "#fff",
      btnFontWeight: "bold",
      btnFontSize: 14,
      btnFontStyle: "normal",
      btnTextAlign: "center",
      btnFontFamily: "Poppins",
      btnTxtDecoration: "unset",
    },
    customizeStatus: false,
    hoverStatus: false,
  },
};
